import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Seo from "../../components/seo"
import FaqItem from "../../components/FaqItem/FaqItem"

import * as styles from "./FAQ.module.scss"
import Page from "../../components/Page/Page"

const FAQ = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulFaqPage(limit: 1, sort: { fields: updatedAt }) {
        edges {
          node {
            faqSections {
              name
              faqs {
                answer {
                  answer
                }
                question
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Page>
      <Seo title="FAQ" />
      <div className={styles.faq}>
        <h1>FAQ</h1>

        {data?.allContentfulFaqPage?.edges?.[0]?.node?.faqSections?.map(
          (section: any) => (
            <div className={styles.faqInner}>
              <h2>{section?.name}</h2>
              {section.faqs?.map((faq: any) => (
                <FaqItem
                  question={faq?.question}
                  answer={faq?.answer?.answer}
                />
              ))}
            </div>
          )
        )}
      </div>
    </Page>
  )
}

export default FAQ
