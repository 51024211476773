import React, { useEffect, useRef, useState } from "react"
import { useSpring, animated } from "@react-spring/web"
import * as styles from "./FaqItem.module.scss"
import ReactMarkdown from "react-markdown"

interface FaqItemProps {
  question: string
  answer: string
}

const FaqItem = ({ question, answer }: FaqItemProps) => {
  const [open, setOpen] = useState(false)
  const ref = useRef<HTMLParagraphElement>(null)
  const [animatedStyles, animate] = useSpring(() => ({ height: "0px" }), [])

  useEffect(() => {
    animate({
      height: (open ? ref?.current?.offsetHeight : 0) + "px",
    })
  }, [animate, ref, open])

  return (
    <div className={styles.faqItem}>
      <button onClick={() => setOpen(!open)}>{question}</button>
      <animated.div style={animatedStyles} className={styles.faqItemAnswer}>
        <div ref={ref} className={styles.faqMarkdown}>
          <ReactMarkdown className="lineBreak">{answer}</ReactMarkdown>
        </div>
      </animated.div>
    </div>
  )
}

export default FaqItem
